import * as React from "react"
import { useState } from 'react'
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import * as styles from './styles.module.scss'
import ExhibitionPreview from '../../components/exhibitionPreview/exhibitionPreview'
import backArrow from '../../svg/back-arrow.svg'
import rightArrow from '../../svg/right-arrow.svg'
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel"

const Exhibition = ({ pageContext }) => {
  const {speakeasyLink, title, date, description, descriptionPreview, imageGallery, artistsWork} = pageContext.content
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const handleModalOpen = () => {
    setIsModalVisible(true)
  }

  return (
    <Layout>
      <div className={styles.root}>
        <button className={styles.backArrow} onClick={() => navigate(-1)}>
          <img data-type='icon' src={backArrow} />
          <div className={styles.title}>Exhibition</div>
        </button>

        <div className="grid-parent">
          <div className={`${styles.headingContainer}`}>
            <div className={styles.headingContainerTitle}>{title}</div>
            <div className="date">{date}</div>
            {/* <img className="image" src={pageContext.content.featuredImage.file.url} /> */}
          </div>
        </div>

        <div className="grid-parent">
          <div className={`${styles.sideInfo}`}>
            {pageContext.content.pressRelease ? <a href={pageContext.content.pressRelease.file.url} target='_blank'><div className={styles.press}>Press Release</div></a> : null}
            {pageContext.content.checkList ? <a href={pageContext.content.checkList.file.url} target='_blank'><div className={styles.checklist}>Checklist</div></a> : null}
            {pageContext.content.speakeasyLink ? <a href={speakeasyLink} target='_blank'><div className={styles.speakeasy}>ENTER SPEAKEASY<span><img data-type='icon' src={rightArrow}/></span></div></a> : null}
          </div>

          {
            descriptionPreview ?
            <div style={{display: isExpanded ? 'none' : 'block'}} className={styles.description}>
              <div dangerouslySetInnerHTML={{ __html: descriptionPreview.childMarkdownRemark.html}}></div>
              <div className={styles.readMore} onClick={() => {setIsExpanded(true)}}>[Read More]</div>
            </div> :
            null
          }
          {
            description ?
            <div style={{display: isExpanded ? 'block' : 'none'}} className={styles.description}>
              <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html}}></div>
              <div className={styles.readMore} onClick={() => {setIsExpanded(false)}}>[Read Less]</div>
            </div> :
            null
          }
        </div>

        {imageGallery ?
          <div className={`grid-parent ${styles.imageGallery}`}>
          <div className={styles.imageContainerInner}>
            <CustomCarousel
              images={imageGallery}
              showImageTitle='true'
            />
          </div>
        </div> :
        null
        }

        {
          artistsWork ?
          <div className={`${styles.artWorkContainer} grid-parent`}>
          {artistsWork.map((work, index) =>
          <div key={index} className={`threeup ${styles.artistWork}`} onClick={handleModalOpen}>
            <ExhibitionPreview
              content={work}
              type='3-up'
              hasModal='true'
              workType='inquery'
            />
          </div>
          )}
        </div> :
        null
        }
      </div>
    </Layout>
  )
}

export default Exhibition
